import * as React from "react"
import Layout from "../../components/layout"
import Star from "../../components/star"
import '../../style.css'

const SwiftLitho = () => {

    const info={
        title:'The Swift Launch Litho',
        internalText:[
          "The Swift launch litho is a one-page litho that describes the science of NASA's Swift mission, as well as providing an activity for students to do on the back. It was produced in September 2004 for distribution at the Swift launch.",
        ],
        pdf:{
          pdf:'swiftLithioPdf.pdf',
          accessible:'swiftLithioPdfDisability.pdf'
        }
    }

  return (
    <Layout>
        <div>
          <h1>{info.title}</h1>
          <Star/>
          <p>{info.internalText}</p>
          {info.pdf.accessible?
          <p>Check out the <a href={`/resourceLinks/${info.pdf.pdf}`} target='_blank' rel="noreferrer">PDF</a> and <a href={`/resourceLinks/${info.pdf.accessible}`} target='_blank' rel="noreferrer">Disability accessible PDF</a></p>
          :
          <p>Check out the <a href={`/resourceLinks/${info.pdf.pdf}`} target='_blank' rel="noreferrer">PDF</a></p>
          }        
        </div>
    </Layout>
  )
}

export default SwiftLitho